.app-container {
  padding: 20px;
  min-height: 100vh;
  background-color: #2a2a2a; /* Dark gray background */
  color: #ffffff; /* White text */
}

.header {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #444444;
}

.header h1 {
  color: #4da6ff; /* Blue title */
}

.sidebar {
  margin-bottom: 20px;
}

.main-panel {
  margin-bottom: 20px;
}

.card {
  background-color: #333333; /* Darker card background */
  color: #ffffff; /* White text */
  border-color: #444444;
}

.card-header {
  background-color: #444444; /* Slightly lighter header */
  color: #ffffff;
  border-color: #555555;
}

.list-group-item {
  background-color: #333333;
  color: #ffffff;
  border-color: #444444;
}

.list-group-item.active {
  background-color: #4da6ff;
  border-color: #4da6ff;
}

.list-group-item:hover {
  background-color: #444444;
}

.form-control {
  background-color: #444444;
  color: #ffffff;
  border-color: #555555;
}

.form-control:focus {
  background-color: #444444;
  color: #ffffff;
  border-color: #4da6ff;
  box-shadow: 0 0 0 0.25rem rgba(77, 166, 255, 0.25);
}

/* Special styling for modal form inputs */
.modal .form-control {
  background-color: #ffffff;
  color: #000000;
  border-color: #cccccc;
}

.modal .form-control:focus {
  background-color: #ffffff;
  color: #000000;
  border-color: #4da6ff;
  box-shadow: 0 0 0 0.25rem rgba(77, 166, 255, 0.25);
}

.btn-primary {
  background-color: #4da6ff;
  border-color: #4da6ff;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.alert-info {
  background-color: #333333;
  color: #4da6ff;
  border-color: #4da6ff;
}

.chat-container {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #444444;
  border-radius: 5px;
  padding: 15px;
  background-color: #333333;
  margin-bottom: 15px;
}

.chat-message {
  margin-bottom: 15px;
  display: flex;
}

.chat-message.user {
  justify-content: flex-end;
}

.chat-message.bot {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.chat-message.user .message-content {
  background-color: #4da6ff;
  color: white;
  border-bottom-right-radius: 5px;
}

.chat-message.bot .message-content {
  background-color: #444444;
  color: #ffffff;
  border-bottom-left-radius: 5px;
}

.message-alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  min-width: 300px;
  z-index: 1000;
}

/* Language selector styles */
.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.language-selector .dropdown-menu {
  background-color: #333333;
  border-color: #444444;
}

.language-selector .dropdown-item {
  color: #ffffff;
}

.language-selector .dropdown-item:hover {
  background-color: #444444;
}

.language-selector .dropdown-item.active {
  background-color: #4da6ff;
}

/* Fix for dark theme form elements */
.form-label {
  color: #ffffff;
}

/* Fix for modal form labels */
.modal .form-label {
  color: #000000;
}

a {
  color: #4da6ff;
}

a:hover {
  color: #80bdff;
}

/* Add New Bot button at the top of the sidebar */
.add-bot-button {
  margin-bottom: 15px;
}
